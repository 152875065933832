// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-form-submission-tsx": () => import("./../../../src/pages/form-submission.tsx" /* webpackChunkName: "component---src-pages-form-submission-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-unsupported-browser-tsx": () => import("./../../../src/pages/unsupported-browser.tsx" /* webpackChunkName: "component---src-pages-unsupported-browser-tsx" */),
  "component---src-templates-article-entry-tsx": () => import("./../../../src/templates/article-entry.tsx" /* webpackChunkName: "component---src-templates-article-entry-tsx" */),
  "component---src-templates-collection-entry-tsx": () => import("./../../../src/templates/collection-entry.tsx" /* webpackChunkName: "component---src-templates-collection-entry-tsx" */),
  "component---src-templates-page-entry-tsx": () => import("./../../../src/templates/page-entry.tsx" /* webpackChunkName: "component---src-templates-page-entry-tsx" */),
  "component---src-templates-staff-member-entry-tsx": () => import("./../../../src/templates/staff-member-entry.tsx" /* webpackChunkName: "component---src-templates-staff-member-entry-tsx" */)
}

